var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.noLabel && !_vm.viewMode
        ? _c("div", [
            _c("span", { staticStyle: { "font-size": "0.85rem" } }, [
              _vm._v(_vm._s(_vm.label))
            ])
          ])
        : _vm._e(),
      _c("ValidationProvider", {
        attrs: { name: _vm.label, rules: _vm.formFieldRules, vid: _vm.name },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var errors = ref.errors
              return [
                _c("codemirror", {
                  ref: _vm.referefence,
                  attrs: {
                    options: _vm.cmOptions,
                    "error-messages": _vm.formFieldErrors(errors)
                  },
                  model: {
                    value: _vm.value2,
                    callback: function($$v) {
                      _vm.value2 = $$v
                    },
                    expression: "value2"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }